import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "app/store.ts";
import type {
  RefineryAddressFilters,
  RefineryAddressSlice,
} from "./refineryAddressesTypes.ts";

const initialState: RefineryAddressSlice = {
  page: 1,
  rowsPerPage: 25,
  filter: {},
  sort: {
    key: "name",
    order: "asc",
  },
};

export const refineriesSlice = createSlice({
  name: "refineries",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<RefineryAddressSlice["page"]>) => {
      state.page = action.payload;
    },
    setRowsPerPage: (
      state,
      action: PayloadAction<RefineryAddressSlice["rowsPerPage"]>,
    ) => {
      state.rowsPerPage = action.payload;
    },
    setFilter: (
      state,
      action: PayloadAction<
        [
          filterType: keyof RefineryAddressFilters,
          filterValue: string | undefined,
        ]
      >,
    ) => {
      const [filterType, filterValue] = action.payload;
      state.filter[filterType] = filterValue;
    },
    setSort: (state, action: PayloadAction<RefineryAddressSlice["sort"]>) => {
      state.sort = action.payload;
    },
  },
});

export const { setPage, setRowsPerPage, setFilter, setSort } =
  refineriesSlice.actions;

export const selectPage = (state: RootState) => state.refineryAddresses.page;
export const selectRowsPerPage = (state: RootState) =>
  state.refineryAddresses.rowsPerPage;
export const selectFilter = (state: RootState) =>
  state.refineryAddresses.filter;
export const selectSort = (state: RootState) => state.refineryAddresses.sort;

export default refineriesSlice.reducer;
