import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "app/store.ts";
import type { CustomerFilters, CustomerSlice } from "./customersTypes.ts";

const initialState: CustomerSlice = {
  page: 1,
  rowsPerPage: 25,
  filter: {},
  sort: {
    key: "name",
    order: "asc",
  },
};

export const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<CustomerSlice["page"]>) => {
      state.page = action.payload;
    },
    setRowsPerPage: (
      state,
      action: PayloadAction<CustomerSlice["rowsPerPage"]>,
    ) => {
      state.rowsPerPage = action.payload;
    },
    setFilter: (
      state,
      action: PayloadAction<
        [filterType: keyof CustomerFilters, filterValue: string | undefined]
      >,
    ) => {
      const [filterType, filterValue] = action.payload;
      state.filter[filterType] = filterValue;
    },
    setSort: (state, action: PayloadAction<CustomerSlice["sort"]>) => {
      state.sort = action.payload;
    },
  },
});

export const { setPage, setRowsPerPage, setFilter, setSort } =
  customersSlice.actions;

export const selectPage = (state: RootState) => state.customers.page;

export const selectRowsPerPage = (state: RootState) =>
  state.customers.rowsPerPage;

export const selectFilter = (state: RootState) => state.customers.filter;

export const selectSort = (state: RootState) => state.customers.sort;

export default customersSlice.reducer;
