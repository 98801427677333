import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "app/store.ts";
import type { ProductFilters, ProductSlice } from "./productsTypes.ts";

const initialState: ProductSlice = {
  page: 1,
  rowsPerPage: 25,
  filter: {},
  sort: {
    key: "name",
    order: "asc",
  },
};

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<ProductSlice["page"]>) => {
      state.page = action.payload;
    },
    setRowsPerPage: (
      state,
      action: PayloadAction<ProductSlice["rowsPerPage"]>,
    ) => {
      state.rowsPerPage = action.payload;
    },
    setFilter: (
      state,
      action: PayloadAction<
        [filterType: keyof ProductFilters, filterValue: string | undefined]
      >,
    ) => {
      const [filterType, filterValue] = action.payload;
      state.filter[filterType] = filterValue;
    },
    setSort: (state, action: PayloadAction<ProductSlice["sort"]>) => {
      state.sort = action.payload;
    },
  },
});

export const { setPage, setRowsPerPage, setFilter, setSort } =
  productsSlice.actions;

export const selectPage = (state: RootState) => state.products.page;
export const selectRowsPerPage = (state: RootState) =>
  state.products.rowsPerPage;
export const selectFilter = (state: RootState) => state.products.filter;
export const selectSort = (state: RootState) => state.products.sort;

export default productsSlice.reducer;
