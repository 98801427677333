import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "app/store.ts";
import type { RefineryFilters, RefinerySlice } from "./refineriesTypes";

const initialState: RefinerySlice = {
  page: 1,
  rowsPerPage: 25,
  filter: {},
  sort: {
    key: "name",
    order: "asc",
  },
};

export const refineriesSlice = createSlice({
  name: "refineries",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<RefinerySlice["page"]>) => {
      state.page = action.payload;
    },
    setRowsPerPage: (
      state,
      action: PayloadAction<RefinerySlice["rowsPerPage"]>,
    ) => {
      state.rowsPerPage = action.payload;
    },
    setFilter: (
      state,
      action: PayloadAction<
        [filterType: keyof RefineryFilters, filterValue: string | undefined]
      >,
    ) => {
      const [filterType, filterValue] = action.payload;
      state.filter[filterType] = filterValue;
    },
    setSort: (state, action: PayloadAction<RefinerySlice["sort"]>) => {
      state.sort = action.payload;
    },
  },
});

export const { setPage, setRowsPerPage, setFilter, setSort } =
  refineriesSlice.actions;

export const selectPage = (state: RootState) => state.refineries.page;
export const selectRowsPerPage = (state: RootState) =>
  state.refineries.rowsPerPage;
export const selectFilter = (state: RootState) => state.refineries.filter;
export const selectSort = (state: RootState) => state.refineries.sort;

export default refineriesSlice.reducer;
