import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import type { RootState } from "app/store.ts";
import type { User } from "./authTypes.ts";

interface AuthSliceInitialState {
  user: User | null;
}

const token = sessionStorage.getItem("token") || null;
const initialState: AuthSliceInitialState = {
  user: token ? jwtDecode(token) : null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<any>) => {
      state.user = jwtDecode(action.payload.data.access_token);
    },
    removeCredentials: (state) => {
      state.user = null;
    },
  },
});

export const { setCredentials, removeCredentials } = authSlice.actions;

export const selectUser = (state: RootState) => state.auth.user;

export default authSlice.reducer;
