import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "app/store.ts";
import type { TicketFilters, TicketSlice } from "./ticketsTypes.ts";

const initialState: TicketSlice = {
  page: 1,
  rowsPerPage: 100,
  filter: { type: "Type==1" },
  sort: {
    key: "ticketId",
    order: "desc",
  },
};

export const ticketsSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<TicketSlice["page"]>) => {
      state.page = action.payload;
    },
    setRowsPerPage: (
      state,
      action: PayloadAction<TicketSlice["rowsPerPage"]>,
    ) => {
      state.rowsPerPage = action.payload;
    },
    setFilter: (
      state,
      action: PayloadAction<
        [
          filterType: keyof Omit<TicketFilters, "type">,
          filterValue: string | undefined,
        ]
      >,
    ) => {
      const [filterType, filterValue] = action.payload;
      state.filter[filterType] = filterValue;
    },
    removeFilter: (
      state,
      action: PayloadAction<keyof Omit<TicketFilters, "type">>,
    ) => {
      delete state.filter[action.payload];
    },
    setSort: (state, action: PayloadAction<TicketSlice["sort"]>) => {
      state.sort = action.payload;
    },
    setTicketType: (state, action: PayloadAction<TicketFilters["type"]>) => {
      state.filter.type = action.payload;
    },
  },
});

export const {
  setPage,
  setRowsPerPage,
  setFilter,
  removeFilter,
  setSort,
  setTicketType,
} = ticketsSlice.actions;

export const selectPage = (state: RootState) => state.tickets.page;
export const selectRowsPerPage = (state: RootState) =>
  state.tickets.rowsPerPage;
export const selectFilter = (state: RootState) => state.tickets.filter;
export const selectSort = (state: RootState) => state.tickets.sort;

export default ticketsSlice.reducer;
