import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "app/store.ts";
import type { BOLFilters, BOLSlice } from "./bolsTypes.ts";

const initialState: BOLSlice = {
  page: 1,
  rowsPerPage: 25,
  filter: {},
  sort: {
    key: "id",
    order: "desc",
  },
};

export const bolsSlice = createSlice({
  name: "bols",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<BOLSlice["page"]>) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action: PayloadAction<BOLSlice["rowsPerPage"]>) => {
      state.rowsPerPage = action.payload;
    },
    setFilter: (
      state,
      action: PayloadAction<
        [filterType: keyof BOLFilters, filterValue: string | undefined]
      >,
    ) => {
      const [filterType, filterValue] = action.payload;
      state.filter[filterType] = filterValue;
    },
    removeFilter: (state, action: PayloadAction<keyof BOLFilters>) => {
      const filterType = action.payload;
      delete state.filter[filterType];
    },
    setSort: (state, action: PayloadAction<BOLSlice["sort"]>) => {
      state.sort = action.payload;
    },
  },
});

export const { setPage, setRowsPerPage, setFilter, removeFilter, setSort } =
  bolsSlice.actions;

export const selectPage = (state: RootState) => state.bols.page;
export const selectRowsPerPage = (state: RootState) => state.bols.rowsPerPage;
export const selectFilter = (state: RootState) => state.bols.filter;
export const selectSort = (state: RootState) => state.bols.sort;

export default bolsSlice.reducer;
