import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice, createSelector } from "@reduxjs/toolkit";
import type { RootState } from "app/store.ts";
import type { PagePermissions, PermissionsParsed } from "./permissionsTypes.ts";

const storedPermissions = sessionStorage.getItem("permissions");
const permissions: PermissionsParsed | null = storedPermissions
  ? JSON.parse(storedPermissions)
  : null;

const initialState = permissions;

export const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    setPermissions: (_, action: PayloadAction<PermissionsParsed>) =>
      action.payload,
    removePermissions: () => null,
  },
});

export const { setPermissions, removePermissions } = permissionsSlice.actions;

export const selectPermissions = (state: RootState) => state.permissions;
export const selectPagePermissions = createSelector(
  [selectPermissions, (_, itemId: keyof PermissionsParsed) => itemId],
  (pages, itemId): PagePermissions => ({
    isCreate: pages?.[itemId]?.isCreate,
    isDelete: pages?.[itemId]?.isDelete,
    isRead: pages?.[itemId]?.isRead,
    isUpdate: pages?.[itemId]?.isUpdate,
  }),
);

export default permissionsSlice.reducer;
