import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "app/store.ts";
import type { Alert } from "./alertTypes.ts";

const initialState: Alert[] = [];

export const alertSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    addAlert: (state, action: PayloadAction<Alert>) => {
      if (state.length === 5) state.splice(0, 1);
      state.push(action.payload);
    },
    removeAlert: (state, action: PayloadAction<Alert["timestamp"]>) => {
      const index = state.findIndex(
        (alert) => alert.timestamp === action.payload,
      );
      if (index !== -1) state.splice(index, 1);
    },
  },
});

export const { addAlert, removeAlert } = alertSlice.actions;

export const selectAlerts = (state: RootState) => state.alerts;

export default alertSlice.reducer;
