import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "app/store.ts";
import type { BOLMarketFilters, BOLMarketSlice } from "./bolMarketsTypes.ts";

const initialState: BOLMarketSlice = {
  page: 1,
  rowsPerPage: 25,
  filter: {},
  sort: {
    key: "name",
    order: "asc",
  },
};

export const bolMarketsSlice = createSlice({
  name: "bolMarkets",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<BOLMarketSlice["page"]>) => {
      state.page = action.payload;
    },
    setRowsPerPage: (
      state,
      action: PayloadAction<BOLMarketSlice["rowsPerPage"]>,
    ) => {
      state.rowsPerPage = action.payload;
    },
    setFilter: (
      state,
      action: PayloadAction<
        [filterType: keyof BOLMarketFilters, filterValue: string | undefined]
      >,
    ) => {
      const [filterType, filterValue] = action.payload;
      state.filter[filterType] = filterValue;
    },
    setSort: (state, action: PayloadAction<BOLMarketSlice["sort"]>) => {
      state.sort = action.payload;
    },
  },
});

export const { setPage, setRowsPerPage, setFilter, setSort } =
  bolMarketsSlice.actions;

export const selectPage = (state: RootState) => state.bolMarkets.page;
export const selectRowsPerPage = (state: RootState) =>
  state.bolMarkets.rowsPerPage;
export const selectFilter = (state: RootState) => state.bolMarkets.filter;
export const selectSort = (state: RootState) => state.bolMarkets.sort;

export default bolMarketsSlice.reducer;
