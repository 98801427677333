import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "app/store.ts";
import type { RigFilters, RigSlice } from "./rigsTypes.ts";

const initialState: RigSlice = {
  page: 1,
  rowsPerPage: 25,
  filter: {},
  sort: {
    key: "name",
    order: "asc",
  },
};

export const rigsSlice = createSlice({
  name: "rigs",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<RigSlice["page"]>) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action: PayloadAction<RigSlice["rowsPerPage"]>) => {
      state.rowsPerPage = action.payload;
    },
    setFilter: (
      state,
      action: PayloadAction<
        [filterType: keyof RigFilters, filterValue: string | undefined]
      >,
    ) => {
      const [filterType, filterValue] = action.payload;
      state.filter[filterType] = filterValue;
    },
    setSort: (state, action: PayloadAction<RigSlice["sort"]>) => {
      state.sort = action.payload;
    },
  },
});

export const { setPage, setRowsPerPage, setFilter, setSort } =
  rigsSlice.actions;

export const selectPage = (state: RootState) => state.rigs.page;

export const selectRowsPerPage = (state: RootState) => state.rigs.rowsPerPage;

export const selectFilter = (state: RootState) => state.rigs.filter;

export const selectSort = (state: RootState) => state.rigs.sort;

export default rigsSlice.reducer;
