import type { SerializedError } from "@reduxjs/toolkit";
import type { ErrorData } from "app/types/error.ts";
import type { Alert } from "./alertTypes.ts";

export const formatAlert = (error: ErrorData | SerializedError): Alert => {
  const timestamp = Date.now();

  // Make the BE return more consistent error structure, to have fewer conditions like the below ones
  if (error) {
    if ("originalStatus" in error) {
      // {"status": "PARSING_ERROR","originalStatus": 400,"data": "RigLocation name 'DAMIAN TEST' already exists for customer 1076","error": "SyntaxError: Unexpected token 'R', \"RigLocation\"... is not valid JSON }
      return {
        message: error.data,
        severity: "error",
        status: error.originalStatus,
        timestamp,
      };
    }
    if ("error" in error) {
      // {"status": "FETCH_ERROR","error": "TypeError: Failed to fetch"}
      return {
        message: error.error,
        severity: "error",
        status: error.status,
        timestamp,
        title: error.status,
      };
    }
    if ("data" in error) {
      // { "status": 400,"data": {"CustomerId": ["Please enter a CustomerId greater than 0"]} }
      return {
        message: error.data
          ? error.data.error_description || JSON.stringify(error.data)
          : error.status.toString(),
        severity: "error",
        status: error.status,
        timestamp,
        title: error.data?.error || "Error",
      };
    }
  }
  return {
    message: "Error",
    severity: "error",
    timestamp,
    title: "Check console for details",
  };
};
