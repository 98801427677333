import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "app/store.ts";
import type { TruckFilters, TruckSlice } from "./trucksTypes.ts";

const initialState: TruckSlice = {
  page: 1,
  rowsPerPage: 25,
  filter: {},
  sort: {
    key: "name",
    order: "asc",
  },
};

export const trucksSlice = createSlice({
  name: "trucks",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<TruckSlice["page"]>) => {
      state.page = action.payload;
    },
    setRowsPerPage: (
      state,
      action: PayloadAction<TruckSlice["rowsPerPage"]>,
    ) => {
      state.rowsPerPage = action.payload;
    },
    setFilter: (
      state,
      action: PayloadAction<
        [filterType: keyof TruckFilters, filterValue: string | undefined]
      >,
    ) => {
      const [filterType, filterValue] = action.payload;
      state.filter[filterType] = filterValue;
    },
    setSort: (state, action: PayloadAction<TruckSlice["sort"]>) => {
      state.sort = action.payload;
    },
  },
});

export const { setPage, setRowsPerPage, setFilter, setSort } =
  trucksSlice.actions;

export const selectPage = (state: RootState) => state.trucks.page;
export const selectRowsPerPage = (state: RootState) => state.trucks.rowsPerPage;
export const selectFilter = (state: RootState) => state.trucks.filter;
export const selectSort = (state: RootState) => state.trucks.sort;

export default trucksSlice.reducer;
