import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "app/store.ts";
import type {
  MailerTemplateFilters,
  MailerTemplateSlice,
} from "./mailerTemplatesTypes.ts";

const initialState: MailerTemplateSlice = {
  page: 1,
  rowsPerPage: 25,
  filter: {},
  sort: {
    key: "name",
    order: "asc",
  },
};

export const mailerTemplatesSlice = createSlice({
  name: "mailerTemplates",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<MailerTemplateSlice["page"]>) => {
      state.page = action.payload;
    },
    setRowsPerPage: (
      state,
      action: PayloadAction<MailerTemplateSlice["rowsPerPage"]>,
    ) => {
      state.rowsPerPage = action.payload;
    },
    setFilter: (
      state,
      action: PayloadAction<
        [
          filterType: keyof MailerTemplateFilters,
          filterValue: string | undefined,
        ]
      >,
    ) => {
      const [filterType, filterValue] = action.payload;
      state.filter[filterType] = filterValue;
    },
    setSort: (state, action: PayloadAction<MailerTemplateSlice["sort"]>) => {
      state.sort = action.payload;
    },
  },
});

export const { setPage, setRowsPerPage, setFilter, setSort } =
  mailerTemplatesSlice.actions;

export const selectPage = (state: RootState) => state.mailerTemplates.page;
export const selectRowsPerPage = (state: RootState) =>
  state.mailerTemplates.rowsPerPage;
export const selectFilter = (state: RootState) => state.mailerTemplates.filter;
export const selectSort = (state: RootState) => state.mailerTemplates.sort;

export default mailerTemplatesSlice.reducer;
