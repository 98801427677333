import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "app/store.ts";
import type {
  CustomerLogFilters,
  CustomerLogSlice,
} from "./customerLogsTypes.ts";

const initialState: CustomerLogSlice = {
  page: 1,
  rowsPerPage: 25,
  filter: {},
  sort: {
    key: "createdTime",
    order: "desc",
  },
};

export const customerLogsSlice = createSlice({
  name: "customerLogs",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<CustomerLogSlice["page"]>) => {
      state.page = action.payload;
    },
    setRowsPerPage: (
      state,
      action: PayloadAction<CustomerLogSlice["rowsPerPage"]>,
    ) => {
      state.rowsPerPage = action.payload;
    },
    setFilter: (
      state,
      action: PayloadAction<
        [filterType: keyof CustomerLogFilters, filterValue: string | undefined]
      >,
    ) => {
      const [filterType, filterValue] = action.payload;
      state.filter[filterType] = filterValue;
    },
    setSort: (state, action: PayloadAction<CustomerLogSlice["sort"]>) => {
      state.sort = action.payload;
    },
  },
});

export const { setPage, setRowsPerPage, setFilter, setSort } =
  customerLogsSlice.actions;

export const selectPage = (state: RootState) => state.customerLogs.page;
export const selectRowsPerPage = (state: RootState) =>
  state.customerLogs.rowsPerPage;
export const selectFilter = (state: RootState) => state.customerLogs.filter;
export const selectSort = (state: RootState) => state.customerLogs.sort;

export default customerLogsSlice.reducer;
