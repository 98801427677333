import "@fontsource/roboto";
import {
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
} from "@mui/material";
import type { PaletteOptions } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { init, wrapCreateBrowserRouter } from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { routes } from "app/routes/routes.tsx";
import { store } from "app/store.ts";
import "./index.css";

// Routing (react-router wrapped in Sentry.io)
init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_ENVIRONMENT,
});
const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter(routes);

// MUI
const darkPalette: PaletteOptions = {
  mode: "dark",
  primary: {
    main: "#5fa5f6",
  },
  secondary: {
    main: "#90caf9",
  },
};
const lightPalette: PaletteOptions = {
  mode: "light",
  primary: {
    main: "#1565c0",
  },
  secondary: {
    main: "#90caf9",
  },
};

const theme = extendTheme({
  colorSchemes: {
    dark: {
      palette: darkPalette,
    },
    light: {
      palette: lightPalette,
    },
  },
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <CssVarsProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <RouterProvider router={router} />
        </LocalizationProvider>
      </CssVarsProvider>
    </Provider>
  </React.StrictMode>,
);
