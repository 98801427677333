import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "app/store.ts";
import type { UserFilters, UserSlice } from "./usersTypes.ts";

const initialState: UserSlice = {
  page: 1,
  rowsPerPage: 25,
  filter: {},
  sort: {
    key: "lastName",
    order: "asc",
  },
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<UserSlice["page"]>) => {
      state.page = action.payload;
    },
    setRowsPerPage: (
      state,
      action: PayloadAction<UserSlice["rowsPerPage"]>,
    ) => {
      state.rowsPerPage = action.payload;
    },
    setFilter: (
      state,
      action: PayloadAction<
        [filterType: keyof UserFilters, filterValue: string | undefined]
      >,
    ) => {
      const [filterType, filterValue] = action.payload;
      state.filter[filterType] = filterValue;
    },
    setSort: (state, action: PayloadAction<UserSlice["sort"]>) => {
      state.sort = action.payload;
    },
  },
});

export const { setPage, setRowsPerPage, setFilter, setSort } =
  usersSlice.actions;

export const selectPage = (state: RootState) => state.users.page;
export const selectRowsPerPage = (state: RootState) => state.users.rowsPerPage;
export const selectFilter = (state: RootState) => state.users.filter;
export const selectSort = (state: RootState) => state.users.sort;

export default usersSlice.reducer;
