import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "app/store.ts";
import type { SalesRepFilters, SalesRepSlice } from "./salesRepsTypes.ts";

const initialState: SalesRepSlice = {
  page: 1,
  rowsPerPage: 25,
  filter: {},
  sort: {
    key: "lastName",
    order: "asc",
  },
};

export const salesRepsSlice = createSlice({
  name: "salesReps",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<SalesRepSlice["page"]>) => {
      state.page = action.payload;
    },
    setRowsPerPage: (
      state,
      action: PayloadAction<SalesRepSlice["rowsPerPage"]>,
    ) => {
      state.rowsPerPage = action.payload;
    },
    setFilter: (
      state,
      action: PayloadAction<
        [filterType: keyof SalesRepFilters, filterValue: string | undefined]
      >,
    ) => {
      const [filterType, filterValue] = action.payload;
      state.filter[filterType] = filterValue;
    },
    setSort: (state, action: PayloadAction<SalesRepSlice["sort"]>) => {
      state.sort = action.payload;
    },
  },
});

export const { setPage, setRowsPerPage, setFilter, setSort } =
  salesRepsSlice.actions;

export const selectPage = (state: RootState) => state.salesReps.page;

export const selectRowsPerPage = (state: RootState) =>
  state.salesReps.rowsPerPage;

export const selectFilter = (state: RootState) => state.salesReps.filter;

export const selectSort = (state: RootState) => state.salesReps.sort;

export default salesRepsSlice.reducer;
